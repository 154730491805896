export const technologies = [
  'Scala',
  'Kotlin',
  'Typescript',
  'C#',
  // 'Java',
  // 'Python',
  'React',
  'Terraform',
  'Kafka',
  // 'Angular',
  'AWS',
  
]

export const experience: Record<string, string> = {
  'Software Engineer at Kaluza': 'Mar 2020 - Current',
  'Software Engineer at Craneware': 'Jul 2019 - Mar 2020',
}

export const socials: Record<string, string> = {
  GitHub: 'https://github.com/LewisDick',
  LinkedIn: 'https://www.linkedin.com/in/lewis-dick/',
  Resume: 'https://lewisdick.com/resume.pdf'
}

export const education = [
  'BSc (Hons) Computing Science at University of Stirling',
]
