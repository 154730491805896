import React, { useState } from 'react'
import './App.scss'
import { Experience } from './Experience'

enum ComponentType {
  Home,
  Experience,
  Photography,
}

function App() {
  const [component, setComponent] = useState(ComponentType.Experience)

  const handleClick = (v: ComponentType) => {
    setComponent(v)
  }

  return (
    <div className="App">
      <div className="container">
          {component === ComponentType.Experience && <div className="wrapper"><Experience /></div>}
      </div>
    </div>
  )
}

export default App
